var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Navbar', {
    attrs: {
      "nav-light": true
    }
  }), _c('section', {
    staticClass: "bg-half bg-primary d-table w-100"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-lg-12 text-center"
  }, [_c('div', {
    staticClass: "page-next-level"
  }, [_c('h4', {
    staticClass: "title text-white",
    staticStyle: {
      "text-transform": "capitalize"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("verification")) + " ")])])])])])]), _c('section', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "container card public-profile border-0 rounded shadow overflow-hidden"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('h5', {
    staticClass: "text-center",
    staticStyle: {
      "text-transform": "uppercase"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("verifywasiat.list")) + " ")]), this.awaris_array.length != 0 ? _c('div', {
    staticClass: "table-responsive bg-white shadow rounded mt-5"
  }, [_c('table', {
    staticClass: "table mb-0 table-center"
  }, [_c('thead', {
    staticClass: "bg-light"
  }, [_c('tr', [_c('th', {
    staticStyle: {
      "width": "100px"
    }
  }, [_vm._v(_vm._s(_vm.$t("index")))]), _c('th', {
    staticStyle: {
      "width": "200px"
    }
  }, [_vm._v(_vm._s(_vm.$t("code")))]), _c('th', [_vm._v("AWARIS")]), _c('th', {
    staticStyle: {
      "width": "100px"
    }
  }, [_vm._v(_vm._s(_vm.$t("total")))])])]), _vm._l(_vm.awaris_array, function (awaris, index) {
    return _c('tbody', {
      key: index
    }, [_c('tr', [_c('td', [_vm._v(_vm._s(index + 1))]), _c('td', [_vm._v(_vm._s(awaris.code))]), _c('td', [_vm._v(_vm._s(awaris.name))]), _c('td', [_c('router-link', {
      attrs: {
        "to": {
          name: 'wasiat.pengesahan.list-pendaftaran',
          query: {
            organization_id: awaris.id
          }
        }
      }
    }, [_vm._v(_vm._s(awaris.total_pending))])], 1)])]);
  })], 2)]) : _vm._e(), this.bank_array.length != 0 ? _c('div', {
    staticClass: "table-responsive bg-white shadow rounded mt-5"
  }, [_c('table', {
    staticClass: "table mb-0 table-center"
  }, [_c('thead', {
    staticClass: "bg-light"
  }, [_c('tr', [_c('th', {
    staticStyle: {
      "width": "100px"
    }
  }, [_vm._v(_vm._s(_vm.$t("index")))]), _c('th', {
    staticStyle: {
      "width": "200px"
    }
  }, [_vm._v(_vm._s(_vm.$t("code")))]), _c('th', [_vm._v(" " + _vm._s(_vm.$t("bank")) + " ")]), _c('th', {
    staticStyle: {
      "width": "100px"
    }
  }, [_vm._v(_vm._s(_vm.$t("total")))])])]), _vm._l(_vm.bank_array, function (bank, index) {
    return _c('tbody', {
      key: index
    }, [_c('tr', [_c('td', [_vm._v(_vm._s(index + 1))]), _c('td', [_vm._v(_vm._s(bank.code))]), _c('td', [_vm._v(_vm._s(bank.name))]), _c('td', [_c('router-link', {
      attrs: {
        "to": {
          name: 'wasiat.pengesahan.list-pendaftaran',
          query: {
            organization_id: bank.id
          }
        }
      }
    }, [_vm._v(_vm._s(bank.total_pending))])], 1)])]);
  })], 2)]) : _vm._e(), this.koperasi_array.length != 0 ? _c('div', {
    staticClass: "table-responsive bg-white shadow rounded mt-5"
  }, [_c('table', {
    staticClass: "table mb-0 table-center"
  }, [_c('thead', {
    staticClass: "bg-light"
  }, [_c('tr', [_c('th', {
    staticStyle: {
      "width": "100px"
    }
  }, [_vm._v(_vm._s(_vm.$t("index")))]), _c('th', {
    staticStyle: {
      "width": "200px"
    }
  }, [_vm._v(_vm._s(_vm.$t("code")))]), _c('th', [_vm._v(" " + _vm._s(_vm.$t("cooperative")) + " ")]), _c('th', {
    staticStyle: {
      "width": "100px"
    }
  }, [_vm._v(_vm._s(_vm.$t("total")))])])]), _vm._l(_vm.koperasi_array, function (koperasi, index) {
    return _c('tbody', {
      key: index
    }, [_c('tr', [_c('td', [_vm._v(_vm._s(index + 1))]), _c('td', [_vm._v(_vm._s(koperasi.code))]), _c('td', [_vm._v(_vm._s(koperasi.name))]), _c('td', [_c('router-link', {
      attrs: {
        "to": {
          name: 'wasiat.pengesahan.list-pendaftaran',
          query: {
            organization_id: koperasi.id
          }
        }
      }
    }, [_vm._v(_vm._s(koperasi.total_pending))])], 1)])]);
  })], 2)]) : _vm._e(), this.individu_array.length != 0 ? _c('div', {
    staticClass: "table-responsive bg-white shadow rounded mt-5"
  }, [_c('table', {
    staticClass: "table mb-0 table-center"
  }, [_c('thead', {
    staticClass: "bg-light"
  }, [_c('tr', [_c('th', {
    staticStyle: {
      "width": "100px"
    }
  }, [_vm._v(_vm._s(_vm.$t("index")))]), _c('th', {
    staticStyle: {
      "width": "200px"
    }
  }, [_vm._v(_vm._s(_vm.$t("code")))]), _c('th', [_vm._v(" " + _vm._s(_vm.$t("individual")) + " ")]), _c('th', {
    staticStyle: {
      "width": "100px"
    }
  }, [_vm._v(_vm._s(_vm.$t("total")))])])]), _c('tbody', _vm._l(_vm.individu_array, function (rakan, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(_vm._s(index + 1))]), _c('td', [_vm._v(_vm._s(rakan.code))]), _c('td', [_vm._v(_vm._s(rakan.short_name))]), _c('td', [_c('router-link', {
      attrs: {
        "to": {
          name: 'wasiat.pengesahan.list-pendaftaran',
          query: {
            organization_id: rakan.id
          }
        }
      }
    }, [_vm._v(_vm._s(rakan.total_pending))])], 1)]);
  }), 0)])]) : _vm._e(), this.pelanggan_array.length != 0 ? _c('div', {
    staticClass: "table-responsive bg-white shadow rounded mt-5"
  }, [_c('table', {
    staticClass: "table mb-0 table-center"
  }, [_c('thead', {
    staticClass: "bg-light"
  }, [_c('tr', [_c('th', {
    staticStyle: {
      "width": "100px"
    }
  }, [_vm._v(_vm._s(_vm.$t("index")))]), _c('th', {
    staticStyle: {
      "width": "200px"
    }
  }, [_vm._v(_vm._s(_vm.$t("code")))]), _c('th', [_vm._v(" " + _vm._s(_vm.$t("client")) + " ")]), _c('th', {
    staticStyle: {
      "width": "100px"
    }
  }, [_vm._v(_vm._s(_vm.$t("total")))])])]), _vm._l(_vm.pelanggan_array, function (pelanggan, index) {
    return _c('tbody', {
      key: index
    }, [_c('tr', [_c('td', [_vm._v(_vm._s(index + 1))]), _c('td', [_vm._v(_vm._s(pelanggan.code))]), _c('td', [_vm._v(_vm._s(pelanggan.name))]), _c('td', [_c('router-link', {
      attrs: {
        "to": {
          name: 'wasiat.pengesahan.list-pendaftaran',
          query: {
            organization_id: pelanggan.id
          }
        }
      }
    }, [_vm._v(_vm._s(pelanggan.total_pending))])], 1)])]);
  })], 2)]) : _vm._e(), this.kerajaan_array.length != 0 ? _c('div', {
    staticClass: "table-responsive bg-white shadow rounded mt-5"
  }, [_c('table', {
    staticClass: "table mb-0 table-center"
  }, [_c('thead', {
    staticClass: "bg-light"
  }, [_c('tr', [_c('th', {
    staticStyle: {
      "width": "100px"
    }
  }, [_vm._v(_vm._s(_vm.$t("index")))]), _c('th', {
    staticStyle: {
      "width": "200px"
    }
  }, [_vm._v(_vm._s(_vm.$t("code")))]), _c('th', [_vm._v(" " + _vm._s(_vm.$t("government")) + " ")]), _c('th', {
    staticStyle: {
      "width": "100px"
    }
  }, [_vm._v(_vm._s(_vm.$t("total")))])])]), _c('tbody', _vm._l(_vm.kerajaan_array, function (rakan, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(_vm._s(index + 1))]), _c('td', [_vm._v(_vm._s(rakan.code))]), _c('td', [_vm._v(_vm._s(rakan.name))]), _c('td', [_c('router-link', {
      attrs: {
        "to": {
          name: 'wasiat.pengesahan.list-pendaftaran',
          query: {
            organization_id: rakan.id
          }
        }
      }
    }, [_vm._v(_vm._s(rakan.total_pending))])], 1)]);
  }), 0)])]) : _vm._e(), this.swasta_array.length != 0 ? _c('div', {
    staticClass: "table-responsive bg-white shadow rounded mt-5"
  }, [_c('table', {
    staticClass: "table mb-0 table-center"
  }, [_c('thead', {
    staticClass: "bg-light"
  }, [_c('tr', [_c('th', {
    staticStyle: {
      "width": "100px"
    }
  }, [_vm._v(_vm._s(_vm.$t("index")))]), _c('th', {
    staticStyle: {
      "width": "200px"
    }
  }, [_vm._v(_vm._s(_vm.$t("code")))]), _c('th', [_vm._v(" " + _vm._s(_vm.$t("private")) + " ")]), _c('th', {
    staticStyle: {
      "width": "100px"
    }
  }, [_vm._v(_vm._s(_vm.$t("total")))])])]), _c('tbody', _vm._l(_vm.swasta_array, function (rakan, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(_vm._s(index))]), _c('td', [_vm._v(_vm._s(rakan.code))]), _c('td', [_vm._v(_vm._s(rakan.name))]), _c('td', [_c('router-link', {
      attrs: {
        "to": {
          name: 'wasiat.pengesahan.list-pendaftaran',
          query: {
            organization_id: rakan.id
          }
        }
      }
    }, [_vm._v(_vm._s(rakan.total_pending))])], 1)]);
  }), 0)])]) : _vm._e()])])]), _c('Footer'), _c('a', {
    directives: [{
      name: "scroll-to",
      rawName: "v-scroll-to",
      value: '#topnav',
      expression: "'#topnav'"
    }],
    staticClass: "btn btn-icon btn-soft-primary back-to-top",
    attrs: {
      "href": "javascript: void(0);",
      "id": "back-to-top"
    }
  }, [_c('arrow-up-icon', {
    staticClass: "icons"
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }